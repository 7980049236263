<template>
    <div class="Login">
        <div class="tit_img">
            <img src="../../assets/images/login/biao.png" alt="">
        </div>
        <el-card class="box_card">
           <el-row>
                <p class="tit text-center">重置密码</p> 
           </el-row>
           <div class="form">
               <el-form ref="form" :model="form" :rules="rules" label-width="80px" label-position="top">
                    <el-form-item label="手机号:" prop="mobile">
                        <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="短信验证码:" prop="codes">
                        <el-input v-model="form.codes" placeholder="请输入短信验证码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="getCode" :disabled="show">{{sendCon}}</el-button>
                    </el-form-item>
                    <el-form-item label="密码:"  prop="password">
                        <el-input type="password" v-model="form.password" placeholder="请输入密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="rpassword">
                        <el-input type="password" v-model="form.rpassword" placeholder="请确认密码"></el-input>
                    </el-form-item>
                     <el-form-item class="btn">
                        <el-button type="primary" @click="reset">重置密码</el-button>
                    </el-form-item>
                </el-form>
           </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        var validateMobile = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        if (/^1[3456789]\d{9}$/.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.form.rpassword !== '') {
          this.$refs.form.validateField('rpassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      form: {
        mobile: '',
        codes: '',
        password: '',
        rpassword: '',
      },
      rules: {
        mobile: {
          required: true,
          validator: validateMobile,
          message: '请输入手机号',
          trigger: ['blur', 'change'],
        },
        password: { required: true, validator: validatePass, trigger: 'blur' },
        codes: { required: true, message: '请输入验证码', trigger: 'blur' },
        rpassword: {
          required: true,
          validator: validatePass2,
          trigger: 'blur',
        },
      },

      show: false,
      count: '',
      timer: null,
      sendCon: '获取验证码',
        }
    },
    created() {
    this.init()
  },
  methods: {
    init() {},
    link(name) {
      this.$router.push({ name: name })
    },
    reset() {
      let _this = this
      this.$refs.form.validate(valid => {
        if (valid) {
          _this.$http
            .post(process.env.VUE_APP_URL + 'resetPassword', this.form)
            .then(data => {
              if (data.data.code == 200) {
                _this.$message.success('修改成功')
              } else {
                _this.$message.error(data.data.msg)
              }
            })
            .catch(err => {
              _this.$message.error('网络错误，请重试')
              console.log(err)
            })
        } else {
          return false
        }
      })
    },
    getCode() {
      let _this = this
      let params = {
        type: 'reset',
        mobile: this.form.mobile,
      }
      if (/^1[3456789]\d{9}$/.test(params.mobile)) {
        _this.$http
          .post(process.env.VUE_APP_URL + 'sendCode', params)
          .then(data => {
            if (data.data.code == 200) {
              _this.countdown()
              _this.$message.success('验证码已发送')
            } else {
              _this.$message.error(data.data.msg)
            }
          })
          .catch(err => {
            _this.$message.error('网络错误，请重试')

            console.log(err)
          })
      } else {
        this.$message.warning('请输入正确格式的手机号')
        return
      }
    },
    countdown() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.show = true
            this.count--
            this.sendCon = this.count + '秒后再次获取'
          } else {
            this.show = false
            clearInterval(this.timer)
            this.timer = null
            this.sendCon = '获取验证码'
          }
        }, 1000)
      }
    },
  },
}
</script>
<style lang="less" scoped>
    .Login{
        width: 100%;
        height: 100%;
        background:url('../../assets/images/login/bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 20% 0px;
        .tit_img{
            margin: 20px auto;
            width: 60vw;
            img{
                width: 100%;
            }
        }
        h1{
            font-size: 60px;
            color: #FFFFFF;
            text-align: center;
            margin: 0px 0px 15%;
        }
        .box_card{
            width: 80vw;
            margin: 0px auto;
            p{
                font-size: 10px;
            }
            .tit{
                font-weight: 800;
                font-size: 18px;
            }
        }
        .form{
            padding: 20px 20px 0px;
            /deep/.el-form-item__label{
                line-height: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
            .el-form-item{
                margin-bottom: 10px!important;
            }
            .btn{
                text-align: center;
                .el-button{
                    width: 153px;
                    height: 40px;
                    background: #960F23;
                    border-radius: 4px;
                    border: none;
                }
            }
            /deep/.el-form-item__error{
                position: relative;
            }
        }
    }
</style>